<template>
    <div>
        <v-dialog v-model="dialog" max-width="400" persistent @keyup.esc="ask_later">
            <v-card flat class="disable-select">
                <v-toolbar dark color="blue-grey" dense flat>
                    <v-icon class="mr-3" color="brown darken-1">mdi-cookie</v-icon>
                    <v-toolbar-title v-html="$t('dialog-cookie-permission.title')" class="white--text font-weight:bold"></v-toolbar-title>
                </v-toolbar>
                <v-card-text v-html="$t('dialog-cookie-permission.text')" class="pa-4">
                </v-card-text>
                <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn :color="$variables.v.theme.secondary"
                           text
                           @click.native="grant">{{$t('dialog-cookie-permission.grant')}}
                    </v-btn>
                    <v-btn color="grey"
                           text
                           @click.native="ask_later">{{$t('dialog-cookie-permission.ask-later')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <img v-if="enable_jesus"
             src="/assets/img/you_are_awesome.jpg"
             alt=""
             loading="lazy"
             class="animate__animated animate__slideInUp permission-jesus"
             :class="hide_jesus === true ? 'animate__slideOutDown' : ''">
    </div>
</template>
<script>
    export default {
        data() {
            return {
                dialog      : false,
                enable_jesus: false,
                hide_jesus  : false,
            };
        },
        // watch  : {
        //     dialog() { // Wird benötigt weil sonst beim schließen (klick auserhalb) dieses Dialogs die Methoden die Ihn aufrufen nicht weiterarbeiten.
        //         if (!this.dialog)
        //             this.ask_later();
        //     }
        // },
        mounted() {
            let sp = this.func.get_localstoragedata("cookie-permission"); // Get the variable if the user already accepted the cookies
            if (sp === null) // If it is set
                this.open_dialog(); // Open Permission Dialog when not accepted
        },
        methods: {
            open_dialog() {
                this.dialog = true;
            },
            grant() {
                this.$store.cookie_permission = true;
                this.func.set_localstoragedata("cookie-permission", "granted");
                this.dialog = false;

                // Insert Google script into head tag, Ads can load after that
                this.func.insert_google_script();

                this.enable_jesus = true;
                setTimeout(() => {
                    this.hide_jesus = true;
                }, 5000);
            },
            ask_later() {
                this.dialog = false
            }
        }
    }
</script>