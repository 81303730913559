<template>
    <v-list-item :to="navpoint.route" v-if="navpoint.auth !== false && !$user.user.hidden_navigation.includes(navpoint.id)" class="no-underline">
        <v-list-item-action>
            <v-icon :color="navpoint.iconcolor">{{navpoint.icon}}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
            <v-list-item-title>{{$t(navpoint.text)}}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        props: ['navpoint'],
    }
</script>
