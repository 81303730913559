import Vue from 'vue'
import index from './index'
import vuetify from './plugins/vuetify'
import 'animate.css/animate.css'
import './js/global-components'
import router from './router'
import userjs from './js/user'
import storejs from './js/store'
import funcjs from './js/func'
import variablesjs from './js/variables'
import i18n from './i18n'
import moment from 'moment'
import VueClipboard from 'vue-clipboard2'
import meta from 'vue-meta'
import ReadMore from 'vue-read-more'
import CountryFlag from 'vue-country-flag'


Vue.component('country-flag', CountryFlag);

Vue.use(VueClipboard);
Vue.use(meta);
Vue.use(ReadMore);


Vue.config.productionTip = false;


// Global variables / functions
Vue.prototype.func     = funcjs;
Vue.prototype.moment   = moment;
Vue.prototype.eventbus = new Vue();



// Current Version
Vue.prototype.$version = "1.2";

// Clear storage when version is not the current version
if (Vue.prototype.func.get_localstoragedata('$version') !== Vue.prototype.$version)
    localStorage.clear();
// Set the version in the storage
Vue.prototype.func.set_localstoragedata('$version', Vue.prototype.$version);


if (Vue.prototype.func.get_localstoragedata("cookie-permission") === null) // If cookie permission wasn't granted delete the Cookies
    Vue.prototype.func.delete_all_cookies(); // Delete the cookies e. g. Google

// Set Store
Vue.prototype.$store     = Vue.observable(storejs);
Vue.prototype.$user      = Vue.observable(userjs);
Vue.prototype.$variables = Vue.observable(variablesjs);

// Set Language
let lang            = Vue.prototype.func.get_localstoragedata('$lang') ? Vue.prototype.func.get_localstoragedata('$lang') : 'en';
i18n.locale         = lang;
Vue.prototype.$lang = lang;

Vue.prototype.$store.notification = Vue.prototype.func.get_localstoragedata('notifications') ? JSON.parse(Vue.prototype.func.get_localstoragedata('notifications')) : Vue.prototype.$store.notification;


(async () => {
    try {
        let u = Vue.prototype.$user.init();
        let v = Vue.prototype.$variables.init();
        [await u, await v]; // Run and await for both to be done before continue

        if (!Vue.prototype.func.isEmpty(Vue.prototype.$variables.v)) {
            new Vue({
                el     : "#app",
                data() {
                    return {
                        windowWidth : window.windowWidth,
                        windowHeight: window.windowHeight,
                    }
                },
                created() {
                    this.set_window_size();
                    this.$vuetify.theme.dark = true;

                    this.scroll();
                    window.addEventListener("resize", this.set_window_size);
                },
                methods: {
                    set_window_size() {
                        this.windowWidth  = window.innerWidth;
                        this.windowHeight = window.innerHeight;
                    },
                    async get_username(username) {
                        return await this.func.ax("/php/getter.php", {
                            func    : "get_username",
                            username: username,
                        });
                    },
                    scroll() {
                        // Need to be there
                        window.onscroll = () => {
                        };
                    },
                    async force_logout(response = "") {
                        if (response !== "")
                            this.eventbus.$emit("SB", response, "error");
                        this.func.set_localstoragedata("$logout", "true");
                        await this.$router.push(this.$variables.v.navigation.redirect);
                    },
                    check_mod() {
                        return ([this.$variables.v.role_ids.superadmin,
                            this.$variables.v.role_ids.admin,
                            this.$variables.v.role_ids.moderator].indexOf(this.$user.user.roleID) >= 0);
                    },
                    check_admin() {
                        return ([this.$variables.v.role_ids.superadmin,
                            this.$variables.v.role_ids.admin].indexOf(this.$user.user.roleID) >= 0);
                    },
                    check_superadmin() {
                        return ([this.$variables.v.role_ids.superadmin].indexOf(this.$user.user.roleID) >= 0);
                    },
                    async check_response(response, success, errormsg) {
                        if (response === "success")
                            await success();
                        else if (response === "php.invalid-token")
                            await this.$root.force_logout(response);
                        else if (this.$variables.v.debug_show_php_errors || response.substr(0, 4) === "php.")
                            this.eventbus.$emit("SB", response, "error");
                        else
                            this.eventbus.$emit("SB", errormsg, "error");
                    },
                    async check_response_permission(response, success, errormsg) {
                        if (response === "success" || this.func.isObject(response))
                            await success();
                        else if (response === "php.invalid-token-or-missing-permission")
                            await this.$root.force_logout(response);
                        else
                            this.eventbus.$emit("SB", errormsg, "error");
                    },
                    check_captcha(captcha) {
                        return captcha || this.$variables.v.debug_disable_captcha || this.check_mod();
                    }
                },
                vuetify,
                router,
                i18n,
                render : h => h(index)
            });
        }
    } catch (e) {
        alert(e);
    }
})();