<template>
    <div>
        <v-icon
                :color="$variables.v.theme.secondary"
                @click="confirm_logout"
                size="40px">
            mdi-power
        </v-icon>
        <dialog-confirm ref="confirm"></dialog-confirm>
    </div>
</template>

<script>
    export default {
        methods: {
            async confirm_logout() {
                if (await this.$refs.confirm.open(this.$t('logout.dialog-confirm.logout'), this.$t('logout.dialog-confirm.are-you-sure'))) {
                    this.$emit("logout");
                }
            },
        },
    }
</script>