import Vue from 'vue'
import VueRouter from 'vue-router'


const trending_giveaways = () => import('../views/post-views/trending-giveaways');
const all_giveaways      = () => import('../views/post-views/all-giveaways');
const hot_deals          = () => import('../views/post-views/hot-deals');
const all_deals          = () => import('../views/post-views/all-deals');
const mediation          = () => import('../views/post-views/mediation');

const privacy = () => import('../views/privacy imprint/privacy');
const imprint = () => import('../views/privacy imprint/imprint');

const rules             = () => import('../views/sub-support/v1-rules');
const faq               = () => import('../views/sub-support/v2-faq');
const donate            = () => import('../views/sub-support/v3-donate');
const mailsupport       = () => import('../views/sub-support/v4-mailsupport');
const discord           = () => import('../views/sub-support/v5-discord');
const upcoming_features = () => import('../views/sub-support/v6-upcoming-features');

const verification_email          = () => import('../views/verification/verification-email');
const verification_reset_password = () => import('../views/verification/verification-reset-password');

const account        = () => import('../views/account');
const administration = () => import('../views/administration');
const inbox          = () => import('../views/inbox');
const leaderboard    = () => import('../views/leaderboard');
const page_404       = () => import('../views/page-404');
const preferences    = () => import('../views/preferences');
const profile        = () => import('../views/profile');
const profile_edit   = () => import('../views/profile-edit');
const redirect       = () => import('../views/redirect');
const support        = () => import('../views/support');


Vue.use(VueRouter);


const routes = [
    // Trending Giveaways
    {
        path     : '/',
        component: trending_giveaways
    },
    {
        path     : '/p/:postid',
        component: trending_giveaways
    },
    {
        path     : '/trending_giveaways',
        component: trending_giveaways
    },
    {
        path     : '/trending_giveaways/p/:postid',
        component: trending_giveaways
    },

    // All Giveaways
    {
        path     : '/all_giveaways',
        component: all_giveaways,
    },
    {
        path     : '/all_giveaways/p/:postid',
        component: all_giveaways,
    },

    // Hot Deals
    {
        path     : '/hot_deals',
        component: hot_deals
    },
    {
        path     : '/hot_deals/p/:postid',
        component: hot_deals
    },

    // All Deals
    {
        path     : '/all_deals',
        component: all_deals,
    },
    {
        path     : '/all_deals/p/:postid',
        component: all_deals,
    },

    // Mediation
    {
        path     : '/mediation',
        component: mediation,
    },
    {
        path     : '/mediation/p/:postid',
        component: mediation,
    },

    // Profile
    {
        path     : '/profile/id/:profileid',
        component: profile,
    },
    {
        path     : '/profile/id/:profileid',
        component: profile,
    },
    {
        path     : '/profile/id/:profileid/p/:postid',
        component: profile,
    },
    {
        path     : '/profile/edit',
        component: profile_edit,
    },
    {
        path     : '/profile/edit/id/:profileid',
        component: profile_edit,
    },

    // Inbox
    {
        path     : '/inbox',
        component: inbox,
    },


    // Settings and other sites
    {
        path     : '/preferences',
        component: preferences,
    },
    {
        path     : '/account',
        component: account,
    },
    {
        path     : '/leaderboard',
        component: leaderboard,
    },

    // Privacy & Imprint
    {
        path     : '/privacy',
        component: privacy
    },
    {
        path     : '/imprint',
        component: imprint
    },

    // Support & Sub Pages
    {
        path     : '/support',
        component: support,
    },
    {
        path     : '/rules',
        component: rules
    },
    {
        path     : '/faq',
        component: faq
    },
    {
        path     : '/donate',
        component: donate
    },
    {
        path     : '/mailsupport',
        component: mailsupport
    },
    {
        path     : '/discord',
        component: discord
    },
    {
        path     : '/upcoming-features',
        component: upcoming_features
    },

    // Misc
    {
        path     : '/administration',
        component: administration,
    },
    {
        path     : '/administration/p/:postid',
        component: administration,
    },

    {
        path     : '/redirect',
        component: redirect,
    },
    {
        path     : '/redirect/:code',
        component: redirect,
    },


    {
        path     : '/verification/email/:code',
        component: verification_email,
    },
    {
        path     : '/verification/pw/:code',
        component: verification_reset_password,
    },

    {
        path     : "/404",
        component: page_404
    },
    {
        path    : "*",
        redirect: "/404"
    },
];


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router