<template>
    <div class="disable-select">
        <v-app-bar app clipped-left>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="no-outline"></v-app-bar-nav-icon>
            <v-toolbar-title>
                <router-link :to="$variables.v.navigation.default" class="no-underline">
                    <h1 class="website-toolbartitle" v-html="$t('meta.page-title')"></h1>
                </router-link>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <div class="d-none d-md-flex">
                <logout v-if="$user.user.auth" @logout="logout"></logout>
                <dialog-auth v-if="!$user.user.auth"></dialog-auth>
                <language-switcher></language-switcher>
            </div>
        </v-app-bar>


        <v-navigation-drawer
                v-model="drawer"
                app
                clipped>

            <div class="d-flex d-md-none ma-5 align-content-center">
                <logout v-if="$user.user.auth" @logout="logout"></logout>
                <dialog-auth v-if="!$user.user.auth"></dialog-auth>
                <language-switcher></language-switcher>
            </div>

            <v-list>
                <navigation-listitem v-for="navpoint in c_menupoints"
                                     :key="navpoint.id"
                                     :navpoint="navpoint">
                </navigation-listitem>
            </v-list>

            <!-- Information alert (only show when enabled or deadline is not reached) -->
            <div v-if="$variables.v.nav_notification.enabled && new Date() < moment($variables.v.nav_notification.till)" class="navigation-alert-container mt-3 d-none d-sm-block">
                <v-alert
                        dense
                        outlined
                        class="navigation-alert pa-1"
                        :type="$variables.v.nav_notification.type.info ? 'info' :
                               $variables.v.nav_notification.type.success ? 'success' :
                               $variables.v.nav_notification.type.warning ? 'warning' :
                               $variables.v.nav_notification.type.error ? 'error' : 'info'">
                    <span v-html="$variables.v.nav_notification.vals.find(x => x.lang === $lang).text"></span>
                </v-alert>
            </div>

            <v-footer fixed>
                <div class="navigation-footer-daily-views">{{$t("g.daily-viewcounter")}}<span class="navigation-footer-daily-views-number">{{$store.view_counter}}</span>
                </div>
                <div class="navigation-footer-copyright">{{$variables.v.copyright}} - {{new Date().getFullYear() }}</div>
                <div class="navigation-footer-privacy-imprint">
                    <span @click="$router.push($variables.v.navigation.privacy)">{{$t("nav-main.privacy")}}</span> /
                    <span @click="$router.push($variables.v.navigation.imprint)">{{$t("nav-main.imprint")}}</span>
                </div>
                <div class="navigation-footer-refuse-cookies" v-if="$store.cookie_permission">
                    <span @click="confirm_clear_cookies">{{$t("revoke-cookies.trigger")}}</span>
                    <dialog-confirm ref="confirm"></dialog-confirm>
                </div>
            </v-footer>
        </v-navigation-drawer>
    </div>
</template>
<script>
    import language_switcher from "./language-switcher";
    import logout from "./logout";
    import navigation_listitem from "./navigation-listitem";
    import dialog_auth from "../dialogs/dialog-auth"


    export default {
        components: {
            "language-switcher"  : language_switcher,
            "logout"             : logout,
            "navigation-listitem": navigation_listitem,
            "dialog-auth"        : dialog_auth
        },
        data() {
            return {
                drawer: null,
            }
        },
        methods   : {
            async logout() {
                // For redirect page logout as identifier is required to know that
                this.func.set_localstoragedata("$logout", "true");
                await this.$router.push(this.$variables.v.navigation.redirect);
                // Set user.js to default
                this.$user.set_default_user();
                this.eventbus.$emit("SB", "login-register-account.msg.user-logged-out", "success", 3);
            },
            async confirm_clear_cookies() {
                if (await this.$refs.confirm.open(this.$t("revoke-cookies.dialog-confirm.title"), this.$t("revoke-cookies.dialog-confirm.are-you-sure"))) {
                    localStorage.removeItem(btoa("cookie-permission")); // Remove the permission from storage
                    this.$store.cookie_permission = null; // Remove the permission from js
                    this.func.delete_all_cookies(); // Delete the cookies e. g. Google
                    location.reload();
                }
            }
        },
        computed  : {
            c_menupoints() {
                return [
                    {
                        id       : 1,
                        icon     : "mdi-star-outline",
                        iconcolor: "amber",
                        text     : "nav-main.trending-giveaways",
                        route    : this.$variables.v.navigation.default,
                    },
                    {
                        id       : 2,
                        icon     : "mdi-vote",
                        iconcolor: "lime accent-4",
                        text     : "nav-main.all-giveaways",
                        route    : this.$variables.v.navigation.all_giveaways,
                    },
                    {
                        id       : 3,
                        icon     : "mdi-fire",
                        iconcolor: "red darken-4",
                        text     : "nav-main.hot-deals",
                        route    : this.$variables.v.navigation.hot_deals,
                    },
                    {
                        id       : 4,
                        icon     : "mdi-vote",
                        iconcolor: "deep-orange",
                        text     : "nav-main.all-deals",
                        route    : this.$variables.v.navigation.all_deals,
                    },
                    // {
                    //     id       : 5,
                    //     icon     : "mdi-offer",
                    //     iconcolor: "pink",
                    //     text     : "nav-main.mediation",
                    //     route    : this.$variables.v.navigation.mediation,
                    // },
                    {
                        id       : 6,
                        icon     : "mdi-face",
                        iconcolor: "cyan",
                        text     : "nav-main.profile",
                        route    : `${this.$variables.v.navigation.profile_id}${this.$user.user.id}`,
                        auth     : this.$user.user.auth,
                    },
                    // {
                    //     id       : 7,
                    //     icon     : "mdi-message-reply-text",
                    //     iconcolor: "lime",
                    //     text     : "nav-main.inbox",
                    //     route    : this.$variables.v.navigation.inbox,
                    //     auth     : this.$user.user.auth,
                    // },
                    {
                        id       : 8,
                        icon     : "mdi-wrench",
                        iconcolor: "blue-grey",
                        text     : "nav-main.preferences",
                        route    : this.$variables.v.navigation.preferences,
                        auth     : this.$user.user.auth,
                    },
                    {
                        id       : 9,
                        icon     : "mdi-cog",
                        iconcolor: "grey",
                        text     : "nav-main.account",
                        route    : this.$variables.v.navigation.account,
                        auth     : this.$user.user.auth,
                    },
                    {
                        id       : 10,
                        icon     : "mdi-podium",
                        iconcolor: "purple",
                        text     : "nav-main.leaderboard",
                        route    : this.$variables.v.navigation.leaderboard,
                    },
                    {
                        id       : 11,
                        icon     : "mdi-help",
                        iconcolor: "green",
                        text     : "nav-main.support",
                        route    : this.$variables.v.navigation.support,
                    },
                    {
                        id       : 12,
                        icon     : "mdi-desktop-mac-dashboard",
                        iconcolor: "lime",
                        text     : "nav-main.administration",
                        route    : this.$variables.v.navigation.administration,
                        auth     : this.$root.check_mod(), // Super Admin, Admin, Moderator
                    },
                ]
            }
        }

    }
</script>
