import funcjs from './func'


const func = funcjs;

export default {
    user        : {},
    default_user: {
        auth                : false,
        id                  : null,
        username            : null,
        email               : null,
        language            : null,
        roleID              : null,
        votepower           : null,
        hidden_services     : [],
        hidden_itemtypes    : [],
        hidden_navigation   : [],
        last_username_change: null,
        token               : null,
    },
    async init() {
        //this.user       = JSON.parse(JSON.stringify(this.default_user));
        let old_session = JSON.parse(func.get_localstoragedata('$user'));
        if (old_session !== null && old_session.token) {
            let browser_information = func.browserInformation();
            let response            = await func.ax('/php/login.php', {
                func               : "checkToken",
                language           : this.$lang,
                userid             : old_session.id,
                token              : old_session.token,
                browser_information: browser_information,
            });
            if (func.isObject(response))
                if (response.banned_date !== undefined)  // When the object has a ban date than its the ban object
                    this.set_default_user(); // Set default user when banned
                else // When user isnt banned
                    this.set_user(response);
            else
                this.set_default_user();
        }
        else
            this.set_default_user();
    },
    set_user(user) {
        // Set $user.user.user into localStorage to keep user signed in
        this.user.auth                 = true;
        this.user.id                   = user.ID;
        this.user.username             = user.username;
        this.user.email                = user.email;
        this.user.language             = user.language;
        this.user.roleID               = parseInt(user.roleID);
        this.user.votepower            = parseInt(user.votepower);
        this.user.hidden_services      = user.hidden_services.length > 0 ? user.hidden_services.split(";").map(x => parseInt(x)) : [];
        this.user.hidden_itemtypes     = user.hidden_itemtypes.length > 0 ? user.hidden_itemtypes.split(";").map(x => parseInt(x)) : [];
        this.user.hidden_navigation    = user.hidden_navigation.length > 0 ? user.hidden_navigation.split(";").map(x => parseInt(x)) : [];
        this.user.last_username_change = user.last_username_change;
        this.user.token                = user.token;
        func.set_localstoragedata('$user', JSON.stringify(this.user));
    },
    set_default_user() {
        this.user = JSON.parse(JSON.stringify(this.default_user));
        localStorage.removeItem('$user');
    },
    update_user_storage() {
        func.set_localstoragedata('$user', JSON.stringify(this.user));
    }
}