<template>
    <v-select v-model="language"
              :items="$variables.v.languages"
              item-value="lang"
              append-icon=""
              dense
              solo
              :color="$variables.v.theme.secondary"
              class="language-selector">
        <template v-slot:selection="{ item }">
            <div>
                <country-flag :country='item.code'/>
            </div>
        </template>
        <template v-slot:item="{ item }">
            <div class="d-flex">
                <country-flag class="mr-2" :country='item.code'/>
                <span>{{item.country}}</span>
            </div>
        </template>
    </v-select>
</template>

<script>
    export default {
        data() {
            return {
                language: this.$lang,
            }
        },
        watch  : {
            async language() {
                this.$lang = this.language;
                this.func.set_localstoragedata('$lang', this.$lang);
                if (this.$user.user.auth === true)
                    await this.applyUserLanguage();

                this.$i18n.locale = this.language;
                this.$forceUpdate();
            }
        },
        methods: {
            async applyUserLanguage() {
                await this.func.ax("/php/update_user.php", {
                    func    : "update_language",
                    userid  : this.$user.user.id,
                    token   : this.$user.user.token,
                    language: this.language,
                });
            }
        }
    }
</script>