import axios from "axios";

// import main from "main.js";

function createAxios() {
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
        return axios.create({baseURL: "http://localhost:81/"});
    else if (location.hostname !== "gamer-giveaways.com")
        return axios.create({baseURL: "http://" + window.location.host.split(":")[0] + ":81/"});
    else
        return axios.create({baseURL: ''});
}

export default {
    axios: createAxios(),
    ax(url, obj, headers = {}) {
        return this.axios.post(url, JSON.stringify(obj), headers
        ).then((response) => {
            // console.log("----------------------------------- requested data start----------------------------------- ");
            // console.log(response.data);
            // console.log("----------------------------------- requested data stop----------------------------------- ");
            return response.data;
        }, (error) => {
            console.log(error);
        });
    },
    get_localstoragedata(variable) {
        // If Storage Variable exists then get encrypted storage variable else return null
        return localStorage.getItem(btoa(variable)) !== null ? atob(localStorage.getItem(btoa(variable))) : null;
    },
    set_localstoragedata(variable, value) {
        // Encrypt storage variable and value
        localStorage.setItem(btoa(variable), btoa(value));
    },
    isObject(val) {
        return val instanceof Object
    },
    isEmpty(obj) {
        return Object.keys(obj).length === 0
    },
    arrays_equal(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length)
            return false;

        let arr1 = _arr1.concat().sort();
        let arr2 = _arr2.concat().sort();

        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i])
                return false;
        }
        return true;
    },
    insert_google_script() {
        let s   = document.createElement("script");
        s.type  = "text/javascript";
        s.async = true;
        s.src   = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
        document.getElementsByTagName('head')[0].appendChild(s);
    },
    delete_all_cookies() {
        let cookies = document.cookie.split(";");

        for (let i = 0; i < cookies.length; i++) {
            let cookie      = cookies[i];
            let eqPos       = cookie.indexOf("=");
            let name        = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
    },
    compare_by_pos(a, b) {
        if (a.pos < b.pos) {
            return -1;
        }
        if (a.pos > b.pos) {
            return 1;
        }
        return 0;
    },
    check_image_url(uri) {
        //make sure we remove any nasty GET params
        uri            = uri.split('?')[0];
        //moving on, split the uri into parts that had dots before them
        let parts      = uri.split('.');
        //get the last part ( should be the extension )
        let extension  = parts[parts.length - 1];
        //define some image types to test against
        let imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp'];
        //check if the extension matches anything in the list.

        return imageTypes.indexOf(extension) !== -1;
    },
    get_date_time(d, t) {
        let date = this.get_date(d);
        let time = this.get_time(d, t);

        return `${date} ${time}`;
    },
    get_date(d) {
        let date = d ? d : "";

        if (!d) { // If date is null then current Date
            let currentdate = new Date();
            currentdate.setDate(currentdate.getDate() + 7);
            date = `${currentdate.getFullYear()}-${currentdate.getMonth() + 1}-${currentdate.getDate()}`;
        }
        return date;
    },
    get_time(d, t) {
        let time = "";
        if (!d)
            time = "00:00:00";
        else if (!t)
            time = "00:00:00";
        else
            time = `${t}:00`;
        return time;
    },
    add_http(url) {
        // Add http if not there
        if (url !== "" && url.substring(0, 7) !== 'http://' && url.substring(0, 8) !== 'https://')
            return 'http://' + url;
        else
            return url;
    },
    generate_password(length = 50) {
        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!?=)(/&%$§*+~#-_.:,;<>|^°{[]}´`",
            retVal  = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    },
    browserInformation() {
        let nAgt        = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let nameOffset, verOffset, ix;

        // In Opera, the true version is after "Opera" or after "Version"
        if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
// In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
// In Chrome, the true version is after "Chrome"
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
// In Safari, the true version is after "Safari" or after "Version"
        else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
// In Firefox, the true version is after "Firefox"
        else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
// In most other browsers, "name/version" is at the end of userAgent
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) !== -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1)
            fullVersion = fullVersion.substring(0, ix);

        return {
            browser_name     : browserName,
            browser_version  : fullVersion,
            browser_useragent: navigator.userAgent,
            browser_lang     : navigator.language,
            screen_height    : window.screen.availHeight,
            screen_width     : window.screen.availWidth,
        }
// 'Windows 3.11' => 'Win16',
// 'Windows 95' => '(Windows 95)|(Win95)|(Windows_95)',
// 'Windows 98' => '(Windows 98)|(Win98)',
// 'Windows 2000' => '(Windows NT 5.0)|(Windows 2000)',
// 'Windows XP' => '(Windows NT 5.1)|(Windows XP)',
// 'Windows Server 2003' => '(Windows NT 5.2)',
// 'Windows Vista' => '(Windows NT 6.0)',
// 'Windows 7' => '(Windows NT 6.1)',
// 'Windows 8' => '(Windows NT 6.2)|(WOW64)',
// 'Windows 10' => '(Windows 10.0)|(Windows NT 10.0)',
// 'Windows NT 4.0' => '(Windows NT 4.0)|(WinNT4.0)|(WinNT)|(Windows NT)',
// 'Windows ME' => 'Windows ME',
// 'Open BSD' => 'OpenBSD',
// 'Sun OS' => 'SunOS',
// 'Linux' => '(Linux)|(X11)',
// 'Mac OS' => '(Mac_PowerPC)|(Macintosh)',
// 'QNX' => 'QNX',
// 'BeOS' => 'BeOS',
// 'OS/2' => 'OS/2',
// 'Search Bot'=>'(nuhk)|(Googlebot)|(Yammybot)|(Openbot)|(Slurp)|(MSNBot)|(Ask Jeeves/Teoma)|(ia_archiver)'
    },
    vuetify_dragable_dialogs() {
        (function () { // make vuetify dialogs movable
            const d = {};
            document.addEventListener("mousedown", e => {
                const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
                if (e.button === 0 && closestDialog != null && e.target.classList.contains("dialog-drag")) { // element which can be used to move element
                    d.el                  = closestDialog; // element which should be moved
                    d.mouseStartX         = e.clientX;
                    d.mouseStartY         = e.clientY;
                    d.elStartX            = d.el.getBoundingClientRect().left;
                    d.elStartY            = d.el.getBoundingClientRect().top;
                    d.el.style.position   = "fixed";
                    d.el.style.margin     = 0;
                    d.oldTransition       = d.el.style.transition;
                    d.el.style.transition = "none"
                }
            });
            document.addEventListener("mousemove", e => {
                if (d.el === undefined) return;
                d.el.style.left = Math.min(
                    Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
                    window.innerWidth - d.el.getBoundingClientRect().width
                ) + "px";
                d.el.style.top  = Math.min(
                    Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
                    window.innerHeight - d.el.getBoundingClientRect().height
                ) + "px";
            });
            document.addEventListener("mouseup", () => {
                if (d.el === undefined) return;
                d.el.style.transition = d.oldTransition;
                d.el                  = undefined
            });
            setInterval(() => { // prevent out of bounds
                const dialog = document.querySelector(".v-dialog.v-dialog--active");
                if (dialog === null) return;
                dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
                dialog.style.top  = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
            }, 100);
        })();
    }
}