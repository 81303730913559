import funcjs from './func'


const func = funcjs;

export default {
    cookie_permission : func.get_localstoragedata('cookie-permission') === 'granted',
    services           : [],
    itemtypes          : [],
    leaderboard        : [],
    leaderboard_own    : null,
    view_counter       : 0,
    last_captcha_solved: null,
    notification       : {
        permissions: {
            trending_giveaways: false,
            all_giveaways     : false,
            hot_deals         : false,
            all_deals         : false,
        },
        last_ids   : {
            trending_giveaways: null,
            all_giveaways     : null,
            hot_deals         : null,
            all_deals         : null,
        }
    }
}