import Vue from 'vue'


const components = {
    'dialog-create-post-similar-posts': () => import('../components/dialogs/create-post/dialog-create-post-similar-posts'),
    'dialog-create-post-guidelines'   : () => import('../components/dialogs/create-post/dialog-create-post-guidelines'),

    'dialog-confirm': () => import('../components/dialogs/dialog-confirm'),
    'dialog-post'   : () => import('../components/dialogs/post/dialog-post'),
    'dialog-user'   : () => import('../components/dialogs/dialog-user'),

    'GoogleAdSense': () => import('../components/post-page/post/GoogleAdSense'),
    'post'         : () => import('../components/post-page/post/post'),
    'post-tags'    : () => import('../components/post-page/post/post-tags'),
    'post-voting'  : () => import('../components/post-page/post/post-voting'),

    'dragger'       : () => import('../components/dragger'),
    'loading-circle': () => import('../components/loading-circle'),
    'miniview-user' : () => import('../components/miniview-user'),
    'snackbar'      : () => import('../components/snackbar'),
    'vertical-tabs' : () => import('../components/vertical-tabs'),

    'VueHcaptcha': () => import('@hcaptcha/vue-hcaptcha'),
};

Object.entries(components).forEach(([name, component]) => Vue.component(name, component));