<template>
    <v-dialog v-model="dialog" max-width="600px" min-width="360px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                    depressed
                    :color="$variables.v.theme.primary"
                    v-bind="attrs"
                    v-on="on">
                <div>
                    <div class="login-register-button-border">
                        {{$t("login-register-account.login")}}
                    </div>
                    <div>
                        {{$t("login-register-account.register")}}
                    </div>
                </div>
            </v-btn>
        </template>
        <div>
            <v-tabs v-model="selected_tab"
                    :vertical="this.$root.windowWidth > 500"
                    show-arrows
                    :background-color="$variables.v.theme.primary"
                    icons-and-text
                    dark
                    grow>

                <v-tab>
                    <v-icon large>mdi-account</v-icon>
                    <div class="caption py-1">{{$t("login-register-account.tab.login")}}</div>
                </v-tab>
                <v-tab>
                    <v-icon large>mdi-account-outline</v-icon>
                    <div class="caption py-1">{{$t("login-register-account.tab.registration")}}</div>
                </v-tab>


                <v-tab-item>
                    <v-card flat class="px-4" :disabled="loading">
                        <v-card-text>
                            <v-form v-model="valid_login" ref="r_form_login">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="login_username"
                                                      :rules="[rules.required]"
                                                      :label="$t('login-register-account.username')"
                                                      :color="$variables.v.theme.secondary"
                                                      maxlength="30"
                                                      @keyup.enter="login"></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field v-model="login_password"
                                                      :append-icon="show_pw_login ?'mdi-eye':'mdi-eye-off'"
                                                      :type="show_pw_login ? 'text' : 'password'"
                                                      :rules="[rules.required]"
                                                      :label="$t('login-register-account.password')"
                                                      :color="$variables.v.theme.secondary"
                                                      counter @click:append="show_pw_login = !show_pw_login"
                                                      dense
                                                      @keyup.enter="login">
                                        </v-text-field>
                                    </v-col>

                                    <v-col cols="12">
                                        <dialog-auth-reset-password></dialog-auth-reset-password>
                                    </v-col>


                                    <v-col v-if="!$variables.v.debug_disable_captcha"
                                           cols="12"
                                           class="text-center">
                                        <p class="login-register-captcha-hint" v-html="$t('login-register-account.captcha-hint')"></p>

                                        <vue-hcaptcha v-if="!$variables.v.debug_disable_captcha"
                                                      :key="unlock_captcha"
                                                      :sitekey="$variables.v.hcaptcha_sitekey"
                                                      @verify="captcha_login_success=true"
                                                      @expired="captcha_login_success=false"
                                                      @error="captcha_login_success=false"
                                                      theme="dark"></vue-hcaptcha>
                                    </v-col>

                                    <v-col cols="12" class="text-center">
                                        <v-btn x-large
                                               block
                                               depressed
                                               :disabled="!valid_login || (!$variables.v.debug_disable_captcha && !captcha_login_success)"
                                               color="success" :key="unlock_btn_login"
                                               @click.once="login">
                                            <loading-circle v-if="loading"></loading-circle>
                                            <span v-if="!loading">{{$t("login-register-account.login")}}</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card flat class="px-4" :disabled="loading">
                        <v-card-text>
                            <v-form v-model="valid_register" ref="r_form_register">
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="reg_username"
                                                      :rules="[rules.required, rules.min3, rules.max30, rules.no_white_spaces, c_does_usernamename_exist, c_username_not_mail, c_username_not_allowed]"
                                                      :label="$t('login-register-account.username')"
                                                      :color="$variables.v.theme.secondary"
                                                      maxlength="30"
                                                      dense
                                                      @focusout="check_username"
                                                      @keyup.enter="register"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="reg_email"
                                                      :rules="[rules.required, rules.no_white_spaces, rules.valid_email, rules.max150, c_email_not_username]"
                                                      :label="$t('login-register-account.email')"
                                                      :color="$variables.v.theme.secondary"
                                                      maxlength="200"
                                                      dense
                                                      @keyup.enter="register"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="reg_emailrepeat"
                                                      :rules="[rules.required, c_email_match]"
                                                      :label="$t('login-register-account.email-confirm')"
                                                      :color="$variables.v.theme.secondary"
                                                      maxlength="200"
                                                      dense
                                                      @keyup.enter="register"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field v-model="reg_password"
                                                      :append-icon="show_pw_register1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                      :type="show_pw_register1 ? 'text' : 'password'"
                                                      :rules="[rules.required, rules.min8, rules.max100, c_password_not_uername, c_password_not_email]"
                                                      :label="$t('login-register-account.password')"
                                                      :color="$variables.v.theme.secondary"
                                                      :hint="$t('login-register-account.password-min-length')"
                                                      maxlength="100"
                                                      counter
                                                      dense
                                                      @click:append="show_pw_register1 = !show_pw_register1"
                                                      @keyup.enter="register"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <v-text-field block v-model="reg_passwordrepeat"
                                                      :append-icon="show_pw_register2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                      :type="show_pw_register2 ? 'text' : 'password'"
                                                      :rules="[rules.required, c_password_match]"
                                                      :label="$t('login-register-account.password-confirm')"
                                                      :color="$variables.v.theme.secondary"
                                                      maxlength="100"
                                                      counter
                                                      dense
                                                      @focusout="$refs.r_form_register.validate()"
                                                      @click:append="show_pw_register2 = !show_pw_register2"
                                                      @keyup.enter="register"></v-text-field>
                                    </v-col>


                                    <v-col cols="12" class="text-center d-flex">
                                        <v-checkbox class="mt-0"
                                                    v-model="accept_rules"
                                                    :color="$variables.v.theme.secondary">
                                            <template slot="label">
                                                <router-link :to="$variables.v.navigation.rules" class="no-underline">
                                                    {{$t('login-register-account.accept-rules')}}
                                                </router-link>
                                            </template>
                                        </v-checkbox>
                                    </v-col>


                                    <v-col v-if="!$variables.v.debug_disable_captcha"
                                           cols="12"
                                           class="text-center">
                                        <p class="login-register-captcha-hint" v-html="$t('login-register-account.captcha-hint')"></p>

                                        <vue-hcaptcha :key="unlock_captcha"
                                                      :sitekey="$variables.v.hcaptcha_sitekey"
                                                      @verify="captcha_register_success=true"
                                                      @expired="captcha_register_success=false"
                                                      @error="captcha_register_success=false"
                                                      theme="dark"></vue-hcaptcha>
                                    </v-col>

                                    <v-col cols="12" class="text-center">
                                        <v-btn x-large
                                               block
                                               depressed
                                               :disabled="!accept_rules || !valid_register || (!$variables.v.debug_disable_captcha && !captcha_register_success)"
                                               color="success"
                                               :key="unlock_btn_register"
                                               @click.once="register">
                                            <loading-circle v-if="loading"></loading-circle>
                                            <span v-if="!loading">{{$t("login-register-account.register")}}</span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs>
        </div>
    </v-dialog>
</template>

<script>
    import sha256 from "sha256"
    import dialog_auth_reset_password from "./dialog-auth-reset-password";


    export default {
        components: {
            "dialog-auth-reset-password": dialog_auth_reset_password,
        },
        data() {
            return {
                dialog : false,
                loading: false,

                selected_tab            : 0,
                valid_login             : false, // Disable Button when form not valid
                valid_register          : false, // Disable Button when form not valid
                captcha_login_success   : false,
                captcha_register_success: false,
                accept_rules            : false,
                unlock_btn_login        : 0,
                unlock_btn_register     : 100000,
                unlock_captcha          : 200000,
                usernames               : [],
                reg_username            : "",
                reg_email               : "",
                reg_emailrepeat         : "",
                reg_password            : "",
                reg_passwordrepeat      : "",
                login_username          : "",
                login_password          : "",
                show_pw_login           : false, // Eye Icon change
                show_pw_register1       : false,
                show_pw_register2       : false,
                rules                   : {
                    required       : v => (!!v) || this.$t("rules.required"),
                    min3           : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    min8           : v => (v !== undefined && v.length >= 8) || this.$t("rules.min-8-chars"),
                    max30          : v => (v !== undefined && v.length <= 30) || this.$t("rules.max-30-chars"),
                    max100         : v => (v !== undefined && v.length <= 100) || this.$t("rules.max-100-chars"),
                    max150         : v => (v !== undefined && v.length <= 150) || this.$t("rules.max-150-chars"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                    valid_email    : v => (v !== undefined && /.+@.+\..+/.test(v)) || this.$t("rules.email-must-valid"),
                }
            }
        },
        computed  : {
            c_does_usernamename_exist() {
                return () => this.reg_username && !this.usernames.includes(this.reg_username.toLowerCase()) || this.$t("rules.username-exist");
            },
            c_username_not_mail() {
                return () => this.reg_username !== this.reg_email || this.$t("rules.username-is-mail");
            },
            c_email_not_username() {
                return () => this.reg_username !== this.reg_email || this.$t("rules.mail-is-username");
            },
            c_username_not_allowed() {
                if (this.reg_username !== undefined) {
                    let allowed = true;
                    this.$variables.v.forbidden_names.forEach(name => {
                        if (this.reg_username.toLowerCase().includes(name)) {
                            allowed = false;
                        }
                    });
                    return () => allowed || this.$t("rules.username-not-allowed");
                }
                else
                    return true;
            },
            c_password_match() {
                return () => this.reg_password === this.reg_passwordrepeat || this.$t("rules.password-must-match");
            },
            c_password_not_uername() {
                return () => this.reg_password !== this.reg_username || this.$t("rules.password-is-username");
            },
            c_password_not_email() {
                return () => this.reg_password !== this.reg_email || this.$t("rules.password-is-email");
            },
            c_email_match() {
                return () => this.reg_email === this.reg_emailrepeat || this.$t("rules.email-must-match");
            },
        },
        watch     : {
            dialog() {
                if (!this.dialog)
                    this.unlock_captcha++;
            }
        },
        beforeCreate() {
            this.func.vuetify_dragable_dialogs();
        },
        methods   : {
            async check_username() {
                if (!([this.reg_username].indexOf(this.itemtypeid) >= 0)) {
                    let response = await this.$root.get_username(this.reg_username);
                    if (response)
                        this.usernames.push(response.username.toLowerCase());
                }
                this.$refs.r_form_register.validate()
            },
            async login() {
                if (this.$refs.r_form_login.validate() && this.$root.check_captcha(this.captcha_login_success)) {
                    this.loading            = true;
                    let browser_information = this.func.browserInformation();
                    let response            = await this.func.ax("/php/login.php", {
                        func               : "tryLogin",
                        language           : this.$lang,
                        username           : this.login_username,
                        password           : sha256.x2(this.login_password),
                        browser_information: browser_information,
                    });
                    if (this.func.isObject(response)) {
                        if (response.banned_date !== undefined) { // When the object has a ban date than its the ban object
                            if (response.permanent === "1")
                                this.eventbus.$emit("SB", `${this.$t("login-register-account.msg.account-permanent-banned")} ${response.reason}`, "error");
                            else
                                this.eventbus.$emit("SB", `${this.$t("login-register-account.msg.account-temp-banned1")} ${this.moment(response.ban_expiration).format("DD.MM.YYYY - HH:mm")} ${this.$t("login-register-account.msg.account-temp-banned2")} ${response.reason}`, "error");

                            this.unlock_captcha++;
                        }
                        else { // When user isnt banned
                            this.dialog = false;
                            this.$user.set_user(response);
                            this.$i18n.locale = this.$user.language;
                            this.eventbus.$emit("SB", "login-register-account.msg.user-signed-in", "success", 3);

                            // Go to login and from there back to the page where the user was
                            this.func.set_localstoragedata("$route", this.$route.fullPath);
                            await this.$router.push(this.$variables.v.navigation.redirect);
                        }

                        this.login_username = "";
                        this.login_password = "";
                    }
                    else
                        this.eventbus.$emit("SB", response, "error");
                    this.unlock_btn_login++;
                    this.loading = false;
                }
            },
            async register() {
                if (this.$refs.r_form_register.validate() && this.$root.check_captcha(this.captcha_register_success)) {
                    this.loading = true;
                    let response = await this.func.ax("/php/register.php", {
                        language      : this.$lang,
                        username      : this.reg_username.trim(),
                        password      : sha256.x2(this.reg_password),
                        passwordrepeat: sha256.x2(this.reg_passwordrepeat),
                        email         : this.reg_email.trim(),
                        emailrepeat   : this.reg_emailrepeat.trim(),
                        gender        : this.$variables.v.register.gender,
                        description   : this.$variables.v.register.description,
                    });
                    await this.$root.check_response(response, () => {
                        this.eventbus.$emit("SB", "login-register-account.msg.user-created", "success", 15);
                        let username = this.reg_username;
                        this.reset_register();
                        this.selected_tab   = 0;
                        this.login_username = username;
                    }, "login-register-account.msg.error-user-register");
                    this.unlock_btn_register++;
                    this.loading = false;
                }
            },
            reset_register() {
                this.reg_username       = "";
                this.reg_email          = "";
                this.reg_emailrepeat    = "";
                this.reg_password       = "";
                this.reg_passwordrepeat = "";
            }
        },
        directives: {
            "sha256": sha256
        },

    }
</script>