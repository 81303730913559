<template>
    <v-app id="app" v-if="!user">
        <dialog-cookie-permission ref="dialog_cookie_permission"></dialog-cookie-permission>

        <snackbar></snackbar>
        <navigation></navigation>
        <v-main class="main-container">
            <v-card flat class="main-card fill-height">
                <router-view></router-view>
            </v-card>
        </v-main>
    </v-app>
</template>

<script>
    import navigation from "./components/navigation/navigation";
    import dialog_cookie_permission from "./components/dialogs/dialog-cookie-permission";


    export default {
        components: {
            "navigation"               : navigation,
            "dialog-cookie-permission": dialog_cookie_permission
        },
        computed  : {
            user() {
                return this.func.isEmpty(this.$user.user);
            }
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-additional')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
        async created() {
            if(this.$store.cookie_permission === true)
                this.func.insert_google_script();

            await this.check_new_posts();
            this.start_post_checker();
            this.start_settings_refresher();
            this.set_view_counter();
        },
        methods   : {
            async set_view_counter() {
                let response = await this.func.ax("/php/getter.php", {
                    func: "get_view_counter"
                });
                if (this.func.isObject(response))
                    this.$store.view_counter = response.view_counter;
            },
            start_settings_refresher() {
                setTimeout(async () => {
                        await this.$variables.init();
                        this.start_settings_refresher();
                    }, 1000 * 60 * Number(this.$variables.v.refresh_settings_delay_in_min) /* ms * sec * min */
                );
            },
            start_post_checker() {
                setTimeout(async () => {
                        await this.check_new_posts();
                        this.start_post_checker();
                    }, 1000 * 60 * Number(this.$variables.v.notification_delay_in_min) /* ms * sec * min */
                );
            },
            async check_new_posts() {
                let services  = this.$user.user.hidden_services !== undefined && this.$user.user.hidden_services.length !== 0 ? this.$user.user.hidden_services.join(',') : "";
                let itemtypes = this.$user.user.hidden_itemtypes !== undefined && this.$user.user.hidden_itemtypes.length !== 0 ? this.$user.user.hidden_itemtypes.join(',') : "";

                if (this.$store.notification.permissions.trending_giveaways
                    && this.$store.notification.last_ids.trending_giveaways !== null
                    && this.$store.notification.last_ids.trending_giveaways !== undefined) {
                    let response = await this.func.ax("/php/check_new_posts.php", {
                        offer_type        : this.$variables.v.offer_types.giveaway,
                        newest_post_id    : this.$store.notification.last_ids.trending_giveaways,
                        excluded_services : services,
                        excluded_itemtypes: itemtypes,
                        point_requirement : this.$variables.v.post_trending_giveaway_requirement,
                    });

                    if (Number(response.amount) !== 0) {
                        new Notification(this.$t('meta.page-title-unformated'), {
                            body: `${response.amount} ${this.$t('notification.new-post-available')} '${this.$t('nav-main.trending-giveaways')}'"`,
                        });
                        this.$store.notification.last_ids.trending_giveaways = response.last_post_id;
                    }
                }
                if (this.$store.notification.permissions.all_giveaways
                    && this.$store.notification.last_ids.all_giveaways !== null
                    && this.$store.notification.last_ids.all_giveaways !== undefined
                ) {
                    let response = await this.func.ax("/php/check_new_posts.php", {
                        offer_type        : this.$variables.v.offer_types.giveaway,
                        newest_post_id    : this.$store.notification.last_ids.all_giveaways,
                        excluded_services : services,
                        excluded_itemtypes: itemtypes,
                        point_requirement : "",
                    });

                    if (Number(response.amount) !== 0) {
                        new Notification(this.$t('meta.page-title-unformated'), {
                            body: `${response.amount} ${this.$t('notification.new-post-available')} '${this.$t('nav-main.all-giveaways')}'"`,
                        });
                        this.$store.notification.last_ids.all_giveaways = response.last_post_id;
                    }
                }
                if (this.$store.notification.permissions.hot_deals
                    && this.$store.notification.last_ids.hot_deals !== null
                    && this.$store.notification.last_ids.hot_deals !== undefined) {
                    let response = await this.func.ax("/php/check_new_posts.php", {
                        offer_type        : this.$variables.v.offer_types.deal,
                        newest_post_id    : this.$store.notification.last_ids.hot_deals,
                        excluded_services : services,
                        excluded_itemtypes: itemtypes,
                        point_requirement : this.$variables.v.post_hot_deals_requirement,
                    });

                    if (Number(response.amount) !== 0) {
                        new Notification(this.$t('meta.page-title-unformated'), {
                            body: `${response.amount} ${this.$t('notification.new-post-available')} '${this.$t('nav-main.hot-deals')}'"`,
                        });
                        this.$store.notification.last_ids.hot_deals = response.last_post_id;
                    }
                }
                if (this.$store.notification.permissions.all_deals
                    && this.$store.notification.last_ids.all_deals !== null
                    && this.$store.notification.last_ids.all_deals !== undefined) {
                    let response = await this.func.ax("/php/check_new_posts.php", {
                        offer_type        : this.$variables.v.offer_types.deal,
                        newest_post_id    : this.$store.notification.last_ids.all_deals,
                        excluded_services : services,
                        excluded_itemtypes: itemtypes,
                        point_requirement : "",
                    });

                    if (Number(response.amount) !== 0) {
                        new Notification(this.$t('meta.page-title-unformated'), {
                            body: `${response.amount} ${this.$t('notification.new-post-available')} '${this.$t('nav-main.all-deals')}'"`,
                        });
                        this.$store.notification.last_ids.all_deals = response.last_post_id;
                    }
                }
            }
        }
    }
</script>