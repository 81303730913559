<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="500px" maxWidth="500px">
            <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs"
                   v-on="on"
                   class="login-register-forgot-password">{{$t('login-register-account.reset-password.forgot-password')}}</p>
            </template>

            <v-card flat class="disable-select">
                <dragger class="mr-4"></dragger>
                <v-card-title class="text-break">{{$t('login-register-account.reset-password.text')}}</v-card-title>
                <v-form v-model="valid" ref="r_form_reset_password">

                    <v-layout class="text-center mx-10">
                        <v-text-field
                                v-model="email"
                                append-icon="mdi-magnify"
                                :label="$t('login-register-account.reset-password.email')"
                                :color="$variables.v.theme.secondary"
                                :rules="[rules.required, rules.no_white_spaces, rules.valid_email, rules.max150]"
                                maxlength="200"
                                class="default-textfield-width"></v-text-field>
                        <v-text-field
                                v-model="username"
                                append-icon="mdi-magnify"
                                :label="$t('login-register-account.reset-password.username')"
                                :color="$variables.v.theme.secondary"
                                :rules="[rules.required, rules.min3, rules.max30, rules.no_white_spaces]"
                                maxlength="30"
                                class="default-textfield-width"></v-text-field>
                    </v-layout>


                    <v-col class="text-center">
                        <!--Hide captcha when user its disabled-->
                        <vue-hcaptcha v-if="!$variables.v.debug_disable_captcha"
                                      :key="unlock_captcha"
                                      :sitekey="$variables.v.hcaptcha_sitekey"
                                      @verify="captcha_success=true"
                                      @expired="captcha_success=false"
                                      @error="captcha_success=false"
                                      theme="dark"></vue-hcaptcha>

                        <v-btn :disabled="!valid || (!$variables.v.debug_disable_captcha && !captcha_success)"
                               color="success"
                               depressed
                               :key="unlock_request_password"
                               @click.once="request_new_password">
                            {{$t('login-register-account.reset-password.request-new-password')}}
                        </v-btn>
                    </v-col>
                </v-form>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        data() {
            return {
                dialog: false,
                valid : false,

                email   : "",
                username: "",

                captcha_success        : false,
                unlock_captcha         : 0,
                unlock_request_password: 100000,
                rules                  : {
                    required       : v => (!!v) || this.$t("rules.required"),
                    min3           : v => (v !== undefined && v.length >= 3) || this.$t("rules.min-3-chars"),
                    max30          : v => (v !== undefined && v.length <= 30) || this.$t("rules.max-30-chars"),
                    max150         : v => (v !== undefined && v.length <= 150) || this.$t("rules.max-150-chars"),
                    no_white_spaces: v => (v !== undefined && !(v.indexOf(" ") >= 0)) || this.$t("rules.no-spaces-allowed"),
                    valid_email    : v => (v !== undefined && /.+@.+\..+/.test(v)) || this.$t("rules.email-must-valid"),
                }
            }
        },
        watch  : {
            dialog() {
                this.captcha_success = false;
                this.unlock_captcha++;
            },
        },
        methods: {
            async request_new_password() {
                if (this.$refs.r_form_reset_password.validate()
                    && this.email.trim().length > 0
                    && this.username.trim().length > 0
                    && this.$root.check_captcha(this.captcha_success)) {
                    let response = await this.func.ax("/php/request_new_password.php", {
                        language: this.$lang,
                        email   : this.email,
                        username: this.username,
                    });
                    await this.$root.check_response(response, () => {
                        this.dialog = false;
                        this.eventbus.$emit("SB", "login-register-account.reset-password.msg.success", "success", 10);
                    }, "login-register-account.reset-password.msg.error");
                }
                this.email    = "";
                this.username = "";
                this.unlock_request_password++;
            },
        },
    }
</script>